<template>
  <v-card
    :loading="loading"
    class="term-item bg-grey-gradient"
    dark
    @click="$emit('click', value)"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div>
      <v-card-title class="term-title">
        {{ value.name }}
      </v-card-title>
      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-row class="justify-space-between">
          <v-col cols="flex-col bd-img-preview" v-if="image">
            <v-img
              :src="image"
              :lazy-src="image"
            ></v-img>
          </v-col>
          <v-col cols="flex-col">
            <div class="simple-text">
              <p>{{ cutFormatter(value.description, 200) }}</p>
              <p>{{ cutFormatter(value.main_topics, 200) }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="card-actions-bottom"
        v-if="value.tags && value.tags.length"
      >
        <v-chip
          class="ma-2"
          v-for="item in value.tags"
          :key="item.tag.id"
          :color="item.tag.color"
          label
          text-color="white"
        >
          <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
          {{ item.tag.name }}
        </v-chip>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { CASE_STUDIES_SLIDES_IMAGES } from "../../../../constants/defaults/SlidesImages";
import { FormatterHelper } from "../../../../helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      loading: false,
      show: false,
      cutFormatter: FormatterHelper.cutString,
      slideImages: CASE_STUDIES_SLIDES_IMAGES
    };
  },
  components: {},
  computed: {
    image: function () {
      return this.value && this.value.slide_image_key
        ? this.slideImages.find((el) => el.value === this.value.slide_image_key)
            .img
        : undefined;
    },
  },
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>

.bd-img-preview{
  max-width: 140px;
}
.term-item {
  z-index: 2;
  overflow: hidden;

  .term-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .term-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform: translate(-100%);
      background: radial-gradient(
        circle,
        rgba(205, 48, 59, 0.5) 0%,
        rgba(205, 48, 59, 0.6) 50%,
        rgba(205, 48, 59, 0.6) 63%
      );
      transition: all 0.3s;
    }

    &.active::after {
      transform: translate(0%);
    }
  }
}
</style>